.mod-detail {
    .items,
    .item {
        box-sizing: border-box;
        position: relative;
    }

    &.element-template--columns,
    &.element-template--columns-three,
    &.element-template--columns-three-round-image {
        h2 {
            text-align: center;
            margin-bottom: $brown-rem;
            font-weight: 700;
        }

        .item {
            @include clearfix;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 10px 20px 0 rgba(#000, .15);
            text-align: center;

            .image,
            .text {
                @include clearfix;
                box-sizing: border-box;
                position: relative;
            }

            .image {
                border-radius: 10px 10px 0 0;
                overflow: hidden;

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            .text {
                padding: $green-rem;

                h5 {
                    margin-bottom: $blue-rem;

                    &.title {
                        font-weight: 700;
                    }
                }

                @include phone-small-portrait {
                    padding: $pink-rem;
                }
            }
        }
    }

    &.element-template--columns {
        .items {
            @include basic-columns(
                $count: 2,
                $selector: '.item',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: phone-landscape
            );
        }
    }

    &.element-template--columns-three,
    &.element-template--columns-three-round-image {
        .items {
            @include basic-columns(
                $count: 3,
                $selector: '.item',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: tablet-portrait
            );

            @include tablet-portrait {
                @include basic-columns(
                    $count: 2,
                    $selector: '.item',
                    $gutter: ($blue-rem, 2.4%),
                    $gutter-bottom: $blue-rem,
                    $breakpoint: iplusP
                );
            }
        }

        .item {
            .text {
                padding: $green-rem;

                h5 {
                    margin-bottom: .25em;
                }

                p {
                    line-height: 1.3;
                }
            }
        }
    }

    &.element-template--columns-three-round-image {
        .item {
            .image {
                position: relative;
                max-width: 192px;
                width: 80%;
                margin-top: $green-rem;
                margin-right: auto;
                margin-left: auto;
                border-radius: 100%;

                img {
                    border-radius: 100%;
                }
            }
        }
    }

    &.element-template--results {
        h2 {
            text-align: center;
            margin-bottom: $brown-rem;
            font-weight: 700;
        }

        .items {
            @include basic-columns(
                $count: 4,
                $selector: '.item',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: tablet-landscape
            );

            @include tablet-landscape {
                @include basic-columns(
                    $count: 2,
                    $selector: '.item',
                    $gutter: ($blue-rem, 2.4%),
                    $gutter-bottom: $blue-rem,
                    $breakpoint: phone-landscape
                );
            }

            .item {
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 10px 20px 0 rgba(#000, .15);
                text-align: center;

                .image,
                .text {
                    box-sizing: border-box;
                    position: relative;
                    padding: $blue-rem;
                }

                .image {
                    padding-bottom: 0;

                    img {
                        max-width: 100px;
                        width: 100%;
                        height: auto;
                        display: block;
                        margin-right: auto;
                        margin-left: auto;
                    }
                }

                .text {
                    margin-bottom: $green-rem;
                }
            }
        }
    }

    &.element-template--story {
        @include clearfix;
        position: relative;

        h5 {
            box-sizing: border-box;
            display: inline-block;
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            background: #fff;
            padding: $red;
            font-weight: 700;
            border-radius: 5px;
        }

        .swiper-nav-button,
        .thumb-nav-button {
            position: absolute;
            display: inline-block;
            top: 50%;

            background: {
                image: url('../images/arrow-primary.svg');
                repeat: no-repeat;
                position: center;
                size: contain;
            }

            &.swiper-button-prev,
            &.thumb-button-prev {
                transform: rotate(180deg);
            }

            &.swiper-button-disabled {
                opacity: .35;
            }
        }

        .swiper-pagination-bullets {
            span {
                box-sizing: border-box;
                background: rgba($theme-primary-color, .25);
                opacity: 1;
                margin-right: .15em;
                margin-left: .15em;

                &.swiper-pagination-bullet-active {
                    background: $theme-primary-color;
                }
            }
        }

        .story-container {
            @include basic-columns(
                $count: 2,
                $selector: '.swiper-container',
                $dimensions: (43%, 57%),
                $gutter: ($magenta, 6.3%),
                $gutter-bottom: 0,
                $breakpoint: menubreak
            );

            @include breakpoint(menubreak) {
                @include basic-columns(
                    $count: 2,
                    $selector: '.swiper-container',
                    $dimensions: (30%, 70%),
                    $gutter: ($green, 3.2%),
                    $gutter-bottom: 0,
                    $breakpoint: iplus
                );
            }

            .swiper-container {
                @include clearfix;
                position: relative;
                box-sizing: border-box;
            }

            .swiper-container-images {
                .swiper-wrapper {
                    &:before {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        z-index: 3;
                        content: '';
                        display: block;
                        pointer-events: none;
                        background: {
                            image: url('../images/image-frame-front.svg');
                            size: cover;
                            position: center;
                            repeat: no-repeat;
                        }
                        width: 100%;
                        height: 100%;
                    }

                    .swiper-slide {
                        z-index: 2;
                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
                }

                @include breakpoint(iplus) {
                    &:nth-child(1n) {
                        width: 80%;
                        margin-right: auto;
                        margin-left: auto;
                        margin-bottom: 0;
                    }
                }
            }

            .swiper-container-messages {
                .swiper-wrapper {
                    padding-top: $magenta-rem;
                }

                .item {
                    overflow-x: hidden;
                }

                .text {
                    h2.alt {
                        margin-bottom: $blue-rem;
                    }

                    h6 {
                        font-weight: 700;

                        + h2:not( .alt ) {
                            font-weight: 100;
                            margin-bottom: $blue-rem;
                        }
                    }
                }

                @include breakpoint(iplus) {
                    .swiper-wrapper {
                        padding-top: 0;
                    }
                }
            }

            .swiper-nav-container {
                box-sizing: border-box;
                position: absolute;
                bottom: $green*-1;
                left: 0;
                width: 100%;
                display: none;

                .swiper-nav-button {
                    z-index: 2;
                    width: $blue;
                    height: $blue;
                }

                .swiper-pagination {
                    position: relative;
                    z-index: 1;
                    top: -7px;
                }
            }

            @include breakpoint(menubreak) {
                .swiper-nav-container {
                    display: block;
                }
            }

            @include breakpoint(iplus) {
                .swiper-nav-container {
                    bottom: $red*-1;
                }
            }
        }

        .thumbs-container {
            box-sizing: border-box;
            padding-right: $orange;
            padding-left: $orange;
            position: relative;
            width: 43%;
            height: 75px;

            &:before {
                content: '';
                height: 4px;
                background: #fff;
                border-radius: 2px;
                position: absolute;
                top: calc(50% - 2px);
                right: $brown;
                left: $brown;
            }

            a.thumb {
                display: block;
                position: relative;
                box-sizing: border-box;
                width: 60px;

                &:before {
                    content: '';
                    display: block;
                    height: 5px;
                    border-radius: 2px;
                    position: absolute;
                    z-index: 1;
                    top: 34px;
                    right: $pink*-1;
                    left: $pink*-1;
                    background: #6b949f;
                    opacity: 0;
                    transition: opacity .2s ease-in-out;
                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    border-radius: 100%;
                    box-sizing: border-box;
                    background: #fff;
                    padding: 4px;
                    position: relative;
                    z-index: 2;
                }

                h6 {
                    text-align: center;
                    font-size: 1rem;
                    font-weight: 700;
                    color: #6b949f;
                    //white-space: nowrap;
                    //transform: translateX(-50%);
                }

                &.swiper-slide-active {
                    &:before {
                        opacity: 1;
                    }
                }
            }

            .thumb-nav-button {
                transform: translateY(-50%);
                width: $blue;
                height: $blue;

                &.thumb-button-prev {
                    left: 0;
                    transform: translateY(-50%) rotate(180deg);
                }

                &.thumb-button-next {
                    right: 0;
                }
            }

            @include breakpoint(menubreak) {
                display: none;
            }
        }
    }

    &.element-template--accordion {

        h2 {
            text-align: center;
            margin-bottom: $blue-rem;
            font-weight: 700;
        }

        .item.panel__item,
        .panel__heading,
        .panel__content {
            box-sizing: border-box;
            position: relative;
        }

        .item.panel__item {
            margin-bottom: $pink-rem;
        }

        .panel__heading,
        .panel__content {
            box-shadow: 0 10px 20px 0 rgba(#000, .15);
        }

        .panel__heading {
            background: #fff;
            color: $theme-font-color;
            border-radius: 5px 5px 0 0;

            a {
                box-sizing: border-box;
                padding: $pink-rem $orange-rem $pink-rem $blue-rem;
                display: block;

                span.accordion-toggle-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                    padding-right: $blue-rem;
                    padding-left: $blue-rem;
                    height: 100%;

                    &:after {
                        content: '';
                        display: block;
                        background: {
                            image: url('../images/accordion-expand.svg');
                            repeat: no-repeat;
                            size: contain;
                            position: center;
                        }
                        transform-origin: center;
                        transition: transform .3s ease-in-out;
                        width: $blue-rem*1.25;
                        height: $blue-rem*1.25;
                        position: relative;
                        top: 50%;
                        transform: translateZ(0) translateY(-50%) rotate(0deg);
                    }
                }

                &:active span.accordion-toggle-icon:after {
                    transform: translateZ(0) translateY(-50%) rotate(0deg);
                }

                &.active span.accordion-toggle-icon:after {
                    transform: translateZ(0) translateY(-50%) rotate(180deg);
                }

                @include breakpoint(iplusP) {
                    padding: $pink-rem $orange-rem $pink-rem $pink-rem;

                    span.accordion-toggle-icon {
                        padding-right: $pink-rem;
                        padding-left: $pink-rem;
                    }
                }
            }

            #{$headers} {
                display: inline-block;
                color: $theme-font-color;
                line-height: 1.5;
                font-weight: 900;
                margin: 0;
            }
        }

        .panel__content {
            display: none;
            background: #fff;
            padding: $blue $pink;
            border-radius: 0 0 5px 5px;

            &.with-image {
                @include clearfix;
                @include basic-two-columns(
                    $left-width: 30%,
                    $right-width: 70%,
                    $float: right,
                    $gutter: ($euphoria-column-gutter, $euphoria-column-gutter-fallback),
                    $breakpoint: tablet-portrait
                );

                .column {
                    position: relative;
                    box-sizing: border-box;

                    &.image {
                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}