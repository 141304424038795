:root .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    @include breakpoint-2020($breakpoint-tablet-horizontal) {
        margin-left: 0;
        margin-right: 0;
    }

    .grid__column {
        flex: 0 0 auto;

        @include breakpoint-2020($breakpoint-tablet-phone) {
            flex: 1 1 auto;
            margin: 0;
            width: auto;
            width: 100%;

            + .grid__column {
                margin-top: 1em;
            }
        }
    }
}

.grid--reverse {
    flex-direction: row-reverse;
}

.grid--count-2 {
    margin: calc(50px / -2);

    @include breakpoint-2020($breakpoint-tablet-phone) {
        margin: 0;
        width: 100%;
    }

    .grid__column {
        margin: calc(50px / 2);
    }
}

.grid--count-3 {
    margin: calc(60px / -2);

    @include breakpoint-2020($breakpoint-phone) {
        margin: 0;
    }

    .grid__column {
        margin: calc(60px / 2);
    }
}

.grid--count-2 {
    .grid__column {
        width: calc((100% / 2) - 50px);

        @include breakpoint-2020($breakpoint-tablet-phone) {
            width: 100%;
        }
    }
}

.grid--count-3 {
    .grid__column {
        width: calc((100% / 3) - 60px);
    }
}
