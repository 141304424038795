body {
    font: {
        family: $font-family;
        size: $font-size;
        weight: $font-weight;
    }
    color: $theme-font-color;
    line-height: $line-height;

    #{$headers} {
        position: relative;
        font-weight: 100;
        line-height: 1.3;
        margin-top: .25rem;
        margin-bottom: .25rem;

        &:first-child {
            margin-top: 0;
        }

        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: -.15rem;
        }

        + p, + ul, + ol {
            margin-top: -.15rem;
        }
    }

    h1 {
        font-size: 2.222em; // 40px

        strong {
            font-weight: 700;
        }

        @include breakpoint(iplus) {
            font-size: 1.944em; // 35px
        }
    }

    h2 {
        font-size: 1.944em; // 35px
        margin-bottom: $blue-rem;

        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: $pink-rem*-1;
            margin-bottom: $blue-rem;
        }

        strong {
            font-weight: 700;
        }

        &.alt {
            font: {
                family: 'Cedarville Cursive', cursive;
                size: 3.611em;
                weight: 400;
            }
            color: #53717a;
            margin-bottom: 0;
        }

        @include breakpoint(iplus) {
            font-size: 1.389em; // 25px
            margin-bottom: $blue-rem/2;
        }
    }

    h3 {
        font-size: 1.389em; // 25px

        strong {
            font-weight: 700;
        }

        @include breakpoint(iplus) {
            font-size: 1.333em; // 24px
        }
    }

    h4 {
        font-size: 1.333em; // 24px

        strong {
            font-weight: 700;
        }

        @include breakpoint(iplus) {
            font-size: 1.222em; // 24px
        }
    }

    h5 {
        font-size: 1.222em; // 22px

        strong {
            font-weight: 700;
        }

        @include breakpoint(iplus) {
            font-size: 1.111em; // 20px
        }
    }

    h6 {
        font-size: 1.111em; // 20px

        strong {
            font-weight: 700;
        }

        @include breakpoint(iplus) {
            font-size: 1.055em;
        }
    }

    p, ul, ol {
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        padding-left: 1.25em;

        ul, ol {
            margin-top: 0;
            margin-bottom: 0;
        }

        li {
            box-sizing: border-box;
            line-height: 1.5;
        }
    }

    a {
        color: $theme-primary-color;
        text-decoration: none;
    }

    #{$all-buttons} {
        @include button;
        position: relative;
        line-height: $line-height;
        letter-spacing: .5px;
        font-weight: 900;

        &:before,
        &:after {
            box-sizing: border-box;
        }

        &:focus {
            outline: 0;
        }

        &.alt {
            background: $theme-secondary-color;

            &:hover {
                background: lighten($theme-secondary-color, 7%);
            }

            &:active {
                background: $theme-secondary-color;
            }
        }

        &.after {
            &:after {
                content: '';
                position: relative;
                background: {
                    image: url('../images/arrow-white.svg');
                    repeat: no-repeat;
                    position: center;
                    size: contain;
                }
                width: $pink;
                height: $pink;
                display: inline-block;
                transform: translate(7px, 6px);
                transition: background .2s ease-in-out;
            }
        }

        &.doneren {
            background-color: #fff;
            color: $theme-font-color;
            display: inline-flex;
            padding: .7em 1.2em;
            line-height: 2em;
            box-shadow: 0 0 15px 5px rgba(45, 45, 45, 0.1);
            transition: box-shadow .2s ease-in-out;

            &:before {
                content: '';
                display: inline-block;
                width: 2em;
                height: 2em;
                margin-right: .5em;
                background: {
                    image: url("../images/ideal.svg");
                    background-size: contain;
                    repeat: no-repeat;
                    position: center;
                }
            }

            &:hover {
                box-shadow: 0 0 5px 5px rgba(45, 45, 45, 0.25);
            }
        }
    }

    em {

    }

    strong {

    }

    span.tooltip {
        cursor: pointer;
        color: $theme-primary-color;
        font-weight: 900;
        display: inline;
        position: relative;

        &:after {
            display: inline-block;
            content: '';
            background: {
                image: url('../images/tooltip.svg');
                size: 1em;
                repeat: no-repeat;
                position: center;
            }
            width: 1em;
            height: 1em;
        }
    }

    .tooltipster-shadow-customized {
        max-width: 320px;
        box-sizing: border-box;

        .tooltipster-content {
            box-sizing: border-box;
            padding: $pink-rem;
            font-size: .899em;
            font-weight: 300;
            line-height: 1.5;
        }
    }

    blockquote {
        font-size: 1.944em;
        font-weight: 100;
        color: $theme-primary-color;
        margin: 1em 0;
    }

    ul {
        &.circle {
            list-style: none;
            li {
                position: relative;
                &:before {
                    position: absolute;
                    content: "";
                    top: 50%;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    border: 2px solid $theme-primary-color;
                    left: -1em;
                    transform: translateY(-50%);
                }
            }
        }
    }
}