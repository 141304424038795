.columns,
.column {
    box-sizing: border-box;
}

.columns {
    margin-top: $orange-rem;
    margin-bottom: $orange-rem;

    @include breakpoint(iplus) {
        margin-top: $orange-rem/2;
        margin-bottom: $orange-rem/2;
    }

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.column {
    margin-top: $blue-rem;
    margin-bottom: $blue-rem;

    @include breakpoint(iplus) {
        margin-top: $blue-rem/2;
        margin-bottom: $blue-rem/2;
    }

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.columns {
    &.two {
        @include basic-two-columns (
            $gutter: ($euphoria-column-gutter, $euphoria-column-gutter-fallback),
            $breakpoint: iplus
        );
    }

    &.slim-wide {
        @include basic-two-columns (
            $left-width: 38%,
            $right-width: 62%,
            $gutter: ($euphoria-column-gutter, $euphoria-column-gutter-fallback),
            $breakpoint: tablet-portrait
        );
    }

    &.slim-wide.reverse {
        @include basic-two-columns (
            $left-width: 38%,
            $right-width: 62%,
            $float: right,
            $gutter: ($euphoria-column-gutter, $euphoria-column-gutter-fallback),
            $breakpoint: tablet-portrait
        );
    }

    &.three {
        @include basic-columns (
            $count: 3,
            $gutter: ($green, 3.16%),
            $gutter-bottom: $green,
            $breakpoint: tablet-portrait
        );

        @include tablet-portrait {
            @include basic-columns (
                $count: 2,
                $gutter: ($green, 3.16%),
                $gutter-bottom: $green,
                $breakpoint: iplusP
            );
        }
    }
}