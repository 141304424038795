.backgrounded-link {
    position: relative;

    &:hover {
        animation: wobble-hor-bottom 0.8s both;
    }
}

.backgrounded-link__image-wrapper {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.backgrounded-link__image {
    border-radius: 5px;
}

.backgrounded-link__text {
    position: relative;

    padding: 100px 1em;

    background: rgba(0, 0, 0, .25);
    border-radius: 5px;
    color: white;
    transition: background .2s ease-in-out;

    text-align: center;

    .backgrounded-link:hover & {
        background: rgba(0, 0, 0, .17);
    }

    h6 {
        align-items: center;
        display: flex;
        justify-content: center;

        font-size: 1.25em;
        font-weight: 600;
        text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        text-transform: uppercase;

        &:after {
            position: relative;

            content: "";
            display: inline-block;
            height: .7em;
            margin-left: .25em;
            width: .7em;

            background: transparent url('../images/chevron-right-white.svg') no-repeat center;
        }
    }
}
