.mod-text {

    img {
        box-sizing: border-box;
        display: block;
        max-width: 100%;
        height: auto;
    }

    &.element-template--boxed {
        background: #fff;
        padding: $pink-rem;
        border: 1px solid rgba($border-color, .05);
    }
}