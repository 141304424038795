$breakpoint-menu-switch: 1100px;
$breakpoint-tablet-horizontal: 1024px;
$breakpoint-tablet-horizontal-vertical: 1023px;
$breakpoint-tablet-vertical: 768px;
$breakpoint-tablet-phone: 767px;
$breakpoint-phone: 600px;
$breakpoint-phone-small: 500px;

@mixin breakpoint-2020($breakpoint) {
    @media only screen and (max-width: $breakpoint) {
        @content;
    }
}

@mixin breakpoint-min($breakpoint) {
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
}
