.mod-images {
    picture {
        position: relative;
        box-sizing: border-box;
        display: block;
        transform-origin: center;
    }

    img {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        display: block;
    }

    &.element-template--default {
    }

    &.element-template--clean {
    }

    &.element-template--gallery {
        a.thumb {
            position: relative;
            display: block;

            &:before {
                opacity: 0;
                transition: opacity .2s ease-in-out;
                pointer-events: none;
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                width: 2rem;
                height: 2rem;
                top: calc(50% - 1rem);
                left: calc(50% - 1rem);
                background: {
                    image: url('../images/zoom.svg');
                    repeat: no-repeat;
                    position: center;
                    size: contain;
                }
            }

            picture {
                &:after {
                    display: none !important;
                }

                img {
                    opacity: .9;
                    border: 4px solid $theme-secondary-color;
                    transition: opacity .2s ease-in-out;
                    box-sizing: border-box;
                    border-radius: 100%;
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            &:hover {
                &:before {
                    opacity: .9
                }
                picture {
                    img {
                        opacity: 1;
                    }
                }
            }
        }

        @include basic-columns(
            $count: 4,
            $selector: '.thumb',
            $gutter: ($blue-rem, 2.4%),
            $gutter-bottom: $blue-rem/2,
            $breakpoint: tablet-portrait
        );

        @include tablet-portrait {
            @include basic-columns(
                $count: 6,
                $selector: '.thumb',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem/2,
                $breakpoint: iplusP
            );
        }

        @include breakpoint(iplusP) {
            @include basic-columns(
                $count: 3,
                $selector: '.thumb',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem/2
            );
        }
    }

    &.element-template--banner {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        .image {
            backface-visibility: hidden;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            background: {
                size: cover;
                position: center;
                repeat: no-repeat;
            }
        }
    }
    &.element-template--image-background {
        picture {
            &:after {
                position: absolute;
                top: -1px;
                bottom: -1px;
                right: -1px;
                left: -1px;
                z-index: 3;
                content: '';
                display: block;
                pointer-events: none;
                background: {
                    image: url('../images/image-blanco-front.svg');
                    size: cover;
                    position: center;
                    repeat: no-repeat;
                }
                .row.frame & {
                    background-image: url('../images/image-frame-front.svg');
                }
                .row.white & {
                    background-image: url('../images/image-white-front.svg');
                }
            }
        }
    }
}
