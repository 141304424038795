.layout-overlay {
    position: fixed;
    display: none;
    z-index: 60;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, .35);

    &.is-open {
        display: block;
    }
}

.responsive-menu {
    box-sizing: border-box;
    position: fixed;
    overflow: scroll;
    top: 0;
    right: 0;
    z-index: 75;
    width: 320px;
    height: 100%;
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all .3s;
    border-left: 1px solid rgba($border-color, .15);
    background-color: $section-frame-color;

    &.open {
        visibility: visible;
        transform: translate3d(0, 0, 0);
    }

    .fg-element:nth-child(1n) {
        margin: 0;
    }

    .mod-menu {
        ul {
            @include clear-ul;

            &.level-1 {
                margin-bottom: $magenta;
            }
        }

        a {
            box-sizing: border-box;
            display: block;

            &:not( .expand ) {
                position: relative;
                color: $section-frame-font-color;
                padding: $pink-rem;
            }

            &.expand {
                position: absolute;
                background: darken($section-frame-color, 5%);
                top: 0;
                right: 0;

                &:after {
                    content: '';
                    display: block;
                    width: 1em;
                    height: 1em;
                    padding: 1.778em;
                    transform: translateZ(0) rotate(0);
                    transform-origin: center;
                    transition: transform .2s;
                    background: {
                        image: url('../images/expand-menu.svg');
                        repeat: no-repeat;
                        position: center;
                        size: .65em;
                    }
                }
            }
        }

        li {
            position: relative;
            border-top: 1px solid rgba($border-color, .05);

            &.has-children {
                ul {
                    display: none;
                    background: lighten($section-frame-color, 5%);
                }
            }

            ul {
                li {
                    a:before {
                        content: '';
                        background: rgba($border-color, .1);
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 6px;
                        transition: background .2s ease-in-out;
                    }
                }
            }

            &:hover,
            &.active {
                a:not( .expand ) {
                    color: $theme-font-color;
                    background: lighten($section-frame-color, 5%);
                }

                ul {
                    li {
                        &.active a:before,
                        &:hover a:before {
                            background: darken($section-frame-color, 10%);
                        }
                    }
                }
            }

            &.active a.expand {
                background: darken($section-frame-color, 12%);
            }
        }

        &:first-child {

            ul {
                margin-bottom: 0;
            }

            li {
                a {
                    box-sizing: border-box;
                    display: block;

                    &:not( .expand ) {
                        position: relative;
                        background: #fff;
                        color: $theme-secondary-color;
                        padding: $pink-rem;
                        transition: background .2s ease-in-out, color .2s ease-in-out;
                        font-weight: 100;
                        font-size: 1.278em;
                        background: {
                            image: url('../images/arrow-secondary.svg');
                            size: 1em;
                            position: 95% center;
                            repeat: no-repeat;
                            color: #fff;
                        }
                    }
                }

                &:hover,
                &.active {
                    a:not( .expand ) {
                        color: $theme-primary-color;
                        background: {
                            image: url('../images/arrow-primary.svg');
                            size: 1em;
                            position: 95% center;
                            repeat: no-repeat;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}