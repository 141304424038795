.layout-news-post {
    .banner {
        .element-template--boxed {
            padding: $green-rem;

            @include breakpoint(iplusP) {
                padding: $pink-rem;
            }
        }

        .banner-text {
            font-size: 1.222em;
            font-weight: 100;

            h2 {
                margin-bottom: $blue-rem;
                font-size: 1.944rem;
            }

            @include breakpoint(iplusP) {
                font-size: 1em;
            }
        }

        @include breakpoint(menubreak) {
            .banner-text:nth-child(1n) {
                padding-top: 27vh;
                padding-bottom: 27vh;
            }
        }

        @include breakpoint(iplus) {
            .banner-text:nth-child(1n) {
                padding-top: 10vh;
                padding-bottom: 15vh;
            }
        }
    }

    .blog-post {
        picture {
            position: relative;
            display: block;

            &:after {
                position: absolute;
                top: -1px;
                bottom: -1px;
                right: -1px;
                left: -1px;
                z-index: 3;
                content: '';
                display: block;
                pointer-events: none;
                background: {
                    image: url('../images/image-blanco-front.svg');
                    size: cover;
                    position: center;
                    repeat: no-repeat;
                }
            }

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }
}