header {
    @include clearfix;
    position: fixed;
    z-index: 60;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    .hamburger {
        pointer-events: all;
        display: none;
        flex-basis: 25%;
        box-sizing: border-box;
        position: absolute;
        outline: none;
        padding: 1em;
        top: 0;
        bottom: 0;
        right: 0;
        border-left: 1px solid rgba($border-color, .15);
    }

    @include breakpoint(menubreak) {
        display: block;
        position: fixed;
        z-index: 60;
        background: #fff;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
        padding: $red-rem*1.5 0;
        box-shadow: 0 1px 2px 0 rgba($border-color, .15);

        .hamburger {
            display: flex;
        }
    }

    .content {
        display: flex;
        flex-flow: row wrap;
    }

    .fg-element {
        margin-top: 0;
        margin-bottom: 0;
        pointer-events: all;
    }

    .logo-container {
        position: relative;
        z-index: 10;
        flex-flow: row wrap;
        align-items: center;
        flex-basis: 329px;

        &:before {
            content: '';
            display: block;
            pointer-events: none;
            background: {
                image: url('../images/logo-bg.svg');
                repeat: no-repeat;
                position: center top;
            }
            width: 133%;
            height: 133%;
            position: absolute;
            z-index: 5;
            transform: translateX(-15%);
        }

        @include breakpoint(menubreak) {
            flex-basis: 75%;
            padding-right: 1em;

            &:before {
                display: none;
            }
        }

        #logo {
            position: relative;
            z-index: 6;
            display: block;

            img {
                display: block;
                height: auto;
                margin: 1em auto;
                width: 70%;
            }

            .responsive {
                display: none;

                img {
                    max-width: 264px;
                }
            }

            @include breakpoint(menubreak) {
                .mod-images {
                    display: none;
                }
                .responsive {
                    display: block;

                    .mod-images {
                        display: block;
                    }
                }
            }
        }
    }

    .menus {
        position: relative;
        z-index: 9;
        flex-basis: calc(100% - 329px);

        @include breakpoint(menubreak) {
            display: none;
        }

        ul {
            @include clear-ul;
            @include clearfix;
        }

        li {
            box-sizing: border-box;
            position: relative;
            float: left;
        }

        .top,
        .bottom {
            position: relative;
            box-sizing: border-box;
            padding-left: $green-rem;
        }

        .top {
            z-index: 2;
            background: $theme-primary-color;
            font-size: .889em; // 16px
            font-weight: 300;

            &:before,
            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                background: $theme-primary-color;;
                height: 100%;
                pointer-events: none;
                top: 0;
                bottom: 0;
            }

            &:before {
                width: 4em;
                left: -4em;
            }

            &:after {
                width: calc((100vw - 1266px) / 2);
                left: 100%;
            }

            @include breakpoint(custom) {
                &:after {
                    width: 1.1em;
                }
            }

            a {
                display: block;
                color: #fff;
            }

            li {
                position: relative;

                a {
                    color: #fff;
                    padding: $red-rem $blue-rem/2;
                    transition: color .2s ease-in-out;
                }

                &:hover a:not( .expand ),
                &.active a:not( .expand ) {
                    color: $section-frame-font-color;
                }

                &.has-children {
                    a:not( .expand ) {
                        color: #fff;
                        padding-right: $blue-rem;
                    }

                    a.expand {
                        position: absolute;
                        top: calc(50% - .9rem);
                        right: .4rem;
                        width: .65rem;
                        height: .65rem;

                        &:after {
                            content: '';
                            display: block;
                            background: {
                                image: url('../images/expand-menu.svg');
                                size: contain;
                                position: center;
                                repeat: no-repeat;
                            }
                            width: .65rem;
                            height: .65rem;
                            transform: translateZ(0) rotate(0);
                            transform-origin: center;
                            transition: transform .2s ease-in-out;
                        }
                    }

                    &:hover {
                        a:not( .expand ) {
                            color: $section-frame-font-color;
                        }

                        a.expand {
                            &:after {
                                transform: translateZ(0) rotate(180deg);
                            }
                        }

                        > ul.level-2 {
                            display: block;
                        }
                    }

                    &.active a:not( .expand ) {
                        color: $section-frame-font-color;
                    }

                    > ul.level-2 {
                        display: none;
                        position: absolute;
                        top: 100%;
                        left: $red-rem;

                        > li {
                            float: none;
                            white-space: nowrap;

                            > a:not( .expand ) {
                                background: darken($theme-primary-color, 5%);
                                transition: background .2s ease-in-out;
                                padding: $pink-rem*.75 $pink-rem;
                                color: #fff;
                                display: block;
                                border-bottom: 1px solid rgba($border-color, .2);
                            }

                            &:hover a,
                            &.active a {
                                background: $theme-primary-color;
                            }

                            a.expand {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            @include clearfix;
            z-index: 1;

            ul {
                box-sizing: border-box;
                float: left;
                background: #f8f8f8;
                position: relative;
                border-radius: 0 0 4px 0;
                border-bottom: 1px solid rgba($border-color, .1);
                border-right: 1px solid rgba($border-color, .1);

                &:before {
                    content: '';
                    display: inline-block;
                    border-bottom: 1px solid rgba($border-color, .1);
                    position: absolute;
                    background: #f8f8f8;
                    height: calc(100% + 1px);
                    pointer-events: none;
                    top: 0;
                    bottom: 0;
                    width: 8em;
                    left: -8em;
                }

                li {
                    font-size: $pink;

                    &:first-child a {
                        border-left: 1px solid rgba($border-color, .04);
                    }

                    &:last-child {
                        border-radius: 0 0 4px 0;
                        a {
                            border-radius: 0 0 4px 0;
                        }
                    }

                    a {
                        box-sizing: border-box;
                        display: inline-block;
                        color: $theme-secondary-color;
                        padding: $blue/2 $brown/2 $blue/2 $blue/2;
                        border-right: 1px solid rgba($border-color, .04);
                        background: #f8f8f8;
                        transition: color .2s ease-in-out;
                        font-weight: 700;
                    }

                    &:hover,
                    &.active {
                        a {
                            color: $theme-primary-color;

                            &:after {
                                background-image: url('../images/arrow-primary.svg');
                            }
                        }
                    }
                }
            }
        }
    }
}
