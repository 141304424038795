@import "euphoria/all";

@include normalize;

@import "partials/base";
@import "partials/typography";
@import "partials/form";

@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/swiper/dist/css/swiper";
@import "../../node_modules/tooltipster/dist/css/tooltipster.bundle";
@import "../../node_modules/tooltipster/dist/css/plugins/tooltipster/sideTip/themes/tooltipster-sideTip-shadow.min";

$font-size--base: 17px;

@import "functions/index";
@import "mixins/index";
@import "components/index";

body {
    background-color: #f8f8f8;
}

*,
*:before,
*:after,
.fg-element {
    box-sizing: border-box;
}

.fg-element {
    margin-top: $blue-rem;
    margin-bottom: $blue-rem;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

#google_map img {
    max-width: none !important;
}

@import "layout/content";
@import "layout/section";
@import "layout/columns";

@import "layout/header";
@import "layout/responsive-menu";
@import "layout/footer";
@import "layout/maps";

@import "elements/text";
@import "elements/images";
@import "elements/detail";
@import "elements/links";
@import "elements/breadcrumb";
@import "elements/menu";
@import "elements/bloglist";

@import "layout/banner";
@import "pages/home";
@import "pages/default";
@import "pages/news";
@import "pages/news-post";

.hamburger {
    @media only screen and (max-width: 1220px) {
        position: relative !important;
        padding: 0 !important;
        flex-basis: inherit !important;
        margin-left: auto !important;
        padding-left: .5em !important;
    }
}

.login {
    position: fixed;
    z-index: 9;
    right: 1em;
    top: 41px;
    @media only screen and (max-width: 1220px) {
        top: 74px !important;
    }

    a {
        display: inline-block;
        box-sizing: border-box;
        color: #fff;
        background-color: #f26163;
        padding: .5rem 1.111em;
        border-radius: 0 0px 5px 5px;
        transition: background-color .2s;

        &:hover {
            background-color: #f58284;
        }
    }
}
