.image--is-cover {
    display: block;
    height: 100%;
    width: 100%;

    background-color: #fff;
    font-family: 'object-fit: cover;';
    object-fit: cover;
    object-position: center;
}
