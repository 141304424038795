.mod-menu {
    &.element-template--submenu-onpage {
        ul {
            @include clearfix;
            @include clear-ul;
            text-align: center;
        }

        li {
            display: inline-block;
            box-sizing: border-box;
            position: relative;
            margin: $red-rem/2;

            a {
                box-sizing: border-box;
                display: block;
                color: #fff;
                background: $theme-primary-color;
                padding: $red-rem $pink-rem;
                font-weight: 700;
                border-radius: 5px;
                transition: background .3s ease-in-out;
            }

            &:hover a {
                animation: shake-bottom .8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
                background: $theme-secondary-color;
            }

            &.active {
                a {
                    background: $theme-secondary-color;
                }
            }
        }

        @include breakpoint(iplus) {
            display: none;
        }
    }
}
