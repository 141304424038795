.content {
    box-sizing: border-box;
    max-width: calc(1266px + 2em );
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1em;
    padding-left: 1em;

    &.smaller {
        max-width: calc(894px + 2em );
    }

    @include phone-small-portrait {
        padding-right: $pink;
        padding-left: $pink*.75;
    }
}