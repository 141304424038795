.layout-homepage {
    .banner {
        .banner-text {
            padding-top: 37vh;
            padding-bottom: 27vh;
        }

        @include breakpoint(menubreak) {
            .banner-text:nth-child(1n) {
                padding-top: 27vh;
                padding-bottom: 27vh;
            }
        }

        @include breakpoint(iplus) {
            .banner-text:nth-child(1n) {
                padding-top: 10vh;
                padding-bottom: 15vh;
            }

            .element-template--boxed {

                + .mod-text {
                    display: none;
                }
            }
        }
    }
}