.banner {
    position: relative;

    + section {
        padding-top: $orange;

        &:before {
            display: none;
        }

        @include tablet-portrait {
            padding-top: $green;
        }
    }

    &.only-image {
        height: 60vh;
    }

    &:before {
        content: '';
        display: block;
        background: {
            image: url('../images/header-bottom.svg');
            repeat: no-repeat;
            size: cover;
            position: top center;
        }
        width: 100%;
        height: 69px;
        position: absolute;
        pointer-events: none;
        z-index: 1;
        right: 0;
        bottom: -1px;
        left: 0;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:before {
            display: none;
        }
    }

    .banner-text {
        position: relative;
        padding-top: 30vh;
        padding-bottom: 20vh;
        pointer-events: none;

        .fg-element {
            pointer-events: all;
        }

        .button {
            font-size: 1.333em;
            font-weight: 500;
            border-radius: 8px;

            + .button {
                margin-left: $blue-rem;
            }
        }
    }

    .element-template--boxed {
        background: rgba($theme-primary-color, .9);
        border-radius: 8px;
        color: #fff;

        #{$headers} {
            color: #fff;
            margin-bottom: 0;

            + p {
                margin-top: $blue-rem;
            }
        }

        + .mod-text {
            margin-top: 5vh;
        }
    }

    a.scroll-btn {
        position: absolute;
        display: block;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);

        &:after {
            content: '';
            background: {
                image: url('../images/scroll-btn.svg');
                size: contain;
                position: center;
                repeat: no-repeat;
            }
            width: 45px;
            height: 45px;
            display: block;
        }

        &:hover:after {
            animation: jello-vertical .7s both;
        }
    }
}