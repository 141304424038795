$form-error-color: #DF6565 !default;
$form-fieldset-border-color: transparent !default;
$form-input-background-color: #fff !default;
$form-input-border: 1px solid #dbdbdb !default;
$form-input-border-radius: 2px !default;
$form-input-color: $theme-font-color !default;
$form-input-padding: 1.111em 1.111em !default;
$form-input-placeholder-color: lighten($theme-font-color, 5%) !default;
$form-select-background-color: #ffffff !default;
$form-select-border-radius: 0 !default;

.mod-contact-form {
    position: relative;
}

input, textarea, select {
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0;
    box-sizing: border-box;
    display: inline-block;
    font: inherit;
    margin: 0;
    padding: $form-input-padding;
    text-decoration: none;
    line-height: 1;
    -ms-touch-action: auto;
    touch-action: auto;

    &:focus {
        outline: none;
    }
}

input[type="text"], input[type="password"], input[type="email"], textarea, select {
    background-color: $form-input-background-color;
    border: $form-input-border;
    border-radius: $form-input-border-radius;
    color: $form-input-color;
    line-height: normal;
    width: 100%;
    font-weight: 100;

    &::-webkit-input-placeholder {
        color: $form-input-placeholder-color;
        font: inherit;
        font-weight: 100;
    }

    &::-moz-placeholder { /* Firefox 19+ */
        color: $form-input-placeholder-color;
        font: inherit;
        font-weight: 100;
    }

    &:-ms-input-placeholder {
        color: $form-input-placeholder-color;
        font: inherit;
        font-weight: 100;
    }
}

textarea {
    line-height: inherit;
    min-height: 140px;
}

select {
    background-image: url('../images/form/select-icon.png');
    background-repeat: no-repeat;
    background-color: #ffffff;
    background-position: 95% 55%;
    border-radius: $form-select-border-radius;
    font-weight: 100;
    padding: $form-input-padding;
    appearance: none;
}

form {
    @include clearfix;

    h2 {
        color: $theme-secondary-color;
    }

    fieldset {
        border: none;
        padding: 0;
        margin: 1em 0;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        + button {
            clear: both;
        }

        textarea {
            height: 14.85em;
        }

        legend {
            color: $theme-secondary-color;
            font-size: 1.059em;
            font-weight: 800;
            margin: 0 0 $pink-rem/2 0;
            padding: 0;
        }
    }

    .form-field {
        margin: 0 0 1em;
    }

    label {
        font-weight: 400;
        color: $theme-font-color;
    }

    .checkboxbutton,
    .radiobutton {
        padding-top: .25em;
        padding-bottom: .25em;

        label {
            font-weight: 100;
        }
        > label > input {
            margin: 0 .4em;
        }
    }

    .phonenumberinput {
        * {
            display: inline-block;
            margin-right: 2%;
        }
        span {
            display: none;
        }
        label {
            display: block;
            height: auto;
        }
        select {
            width: 30%;
        }
        .phonenumberinput_areacode {
            width: 20%;
        }
        .phonenumberinput_number {
            width: 43%;
            margin: 0;
        }
    }

    button {
        @include clearfix;
        float: right;
    }
}

.mod_contactform_required {
    font-size: .8em;
    opacity: .6;
}

.field {
    margin: 0 0 1em;
}

.error, form .error.not-empty {
    background: none;
    color: $form-error-color;
    display: block;
    float: none;
    font-size: .78em;
    line-height: 1.2;
    margin: .5em 0 0;
    padding: 0;
}

.some-fields-required {
    width: 55%;
    position: absolute;
    bottom: 2em;
    font-size: .8em;
    opacity: .6;

    @include phone-portrait {
        width: 100%;
        position: relative;
        bottom: inherit;
    };
}

/*target Internet Explorer 9 and Internet Explorer 10:*/
@media screen and (min-width: 0 \0
) {
    select {
        background: none;
        padding: 5px;
    }
}