.layout-news {
    .banner {
        .element-template--boxed {
            padding: $green-rem;

            @include breakpoint(iplusP) {
                padding: $pink-rem;
            }
        }

        .banner-text {
            font-size: 1.222em;
            font-weight: 100;

            h2 {
                margin-bottom: $blue-rem;
                font-size: 1.944rem;
            }

            @include breakpoint(iplusP) {
                font-size: 1em;
            }
        }

        @include breakpoint(menubreak) {
            .banner-text:nth-child(1n) {
                padding-top: 27vh;
                padding-bottom: 27vh;
            }
        }

        @include breakpoint(iplus) {
            .banner-text:nth-child(1n) {
                padding-top: 10vh;
                padding-bottom: 15vh;
            }
        }
    }

    .catogory {
        text-align: center;

        h5, ul, li {
            display: inline-block;
        }

        h5 {
            font-weight: 700;
        }

        ul {
            @include clearfix;
            @include clear-ul;

            li {
                position: relative;
                box-sizing: border-box;
                margin: .25em;
                font-size: 1em;
                font-weight: 700;

                a {
                    display: block;
                    background: #cecece;
                    padding: $red-rem/2 $red-rem $red-rem/3 $red-rem;
                    color: #fff;

                    &:before {
                        box-sizing: border-box;
                        content: '';
                        width: 13px;
                        height: 13px;
                        display: inline-block;
                        border: 3px solid #fff;
                        background: #fff;
                        border-radius: 100%;
                        transition: background .2s ease-in-out;
                    }
                }

                &.all-posts {
                    a {
                        background: $theme-secondary-color;
                    }

                    &:hover a:before,
                    &.active a:before {
                        background: $theme-secondary-color;
                    }
                }

                &[class*="nieuws"] {
                    a {
                        background: #3b3561;
                    }

                    &:hover a:before,
                    &.active a:before {
                        background: #3b3561;
                    }
                }
                &[class*="media"] {
                    a {
                        background: $theme-primary-color;
                    }

                    &:hover a:before,
                    &.active a:before {
                        background: $theme-primary-color;
                    }
                }
                &[class*="onderzoek"] {
                    a {
                        background: #ef9552;
                    }

                    &:hover a:before,
                    &.active a:before {
                        background: #ef9552;
                    }
                }
            }
        }
    }

    .blog-posts {
        @include clearfix;
        position: relative;

        @include basic-columns(
            $count: 3,
            $selector: '.blog-post',
            $gutter: ($blue-rem, 2.4%),
            $gutter-bottom: $blue-rem,
            $breakpoint: tablet-landscape
        );

        @include tablet-landscape {
            @include basic-columns(
                $count: 2,
                $selector: '.blog-post',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: iplusP
            );
        }
    }

    .blog-post {
        box-sizing: border-box;
        position: relative;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(#000, .15);
        text-align: center;
        transform: translateZ(0);

        a {
            display: block;
        }

        .post-image,
        .post-content {
            box-sizing: border-box;
            position: relative;
        }

        .post-image {
            padding: $blue-rem;

            a {
                width: 160px;
                margin: 0 auto;
            }
            img {
                box-sizing: border-box;
                width: 100%;
                height: auto;
                display: block;
                border-radius: 100%;
            }
        }

        .post-content {
            padding: $blue-rem;
            padding-top: 0;

            .tag-date {
                margin-bottom: $pink-rem;
                margin-right: .25em;
                margin-left: .25em;

                .tag {
                    display: inline-block;
                    font-size: .611em;
                    font-weight: 700;
                    text-transform: uppercase;
                    padding: $red-rem/1.8 $red-rem $red-rem/4 $red-rem;
                    background: #cacaca;
                    color: #fff;
                    border-radius: 3px;

                    &[class*="nieuws"] {
                        background: #3b3561;
                    }
                    &[class*="media"] {
                        background: $theme-primary-color;
                    }
                    &[class*="onderzoek"] {
                        background: #ef9552;
                    }
                }

                time {
                    margin-right: .25em;
                    margin-left: .25em;
                    display: inline-block;
                    font-size: .833em;
                    color: #3b3b3b;
                }
            }

            h5, h5 a {
                color: $theme-font-color;
                font-weight: 700;
                margin-bottom: 1em;
            }

            .article-button {
                font-weight: 700;
            }
        }

        &:hover {
            animation: wobble-hor-bottom 0.8s both;
        }
    }
}