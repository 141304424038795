.layout-locations,
.element-template--locations {
    box-sizing: border-box;

    .maps.two {
        box-sizing: border-box;
        padding: 5px;
        background: #fff;
        box-shadow: 0 0 20px 0 rgba($theme-font-color, .15);

        @include basic-columns (
            $count: 2,
            $dimensions: ( 58%, 42%),
            $gutter: (0, 0),
            $gutter-bottom: 0,
            $breakpoint: iplusP
        );
    }

    .column {
        box-sizing: border-box;
        position: relative;
    }

    #infoMap {
        padding: 2rem;

        div[id*="location-"] {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }
}