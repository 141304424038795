.mod-breadcrumbs {
    position: relative;

    ul {
        @include clearfix;
        @include clear-ul;
    }

    li {
        display: inline-block;
        font-size: .882em;

        &:first-child {
            margin-right: .15em;
        }
    }

    a {
        display: inline-block;
        transform: translateZ(0) translateY(0);
        transition: transform .2s ease-in-out;

        &:hover {
            transform: translateZ(0) translateY(-2px);
        }
    }

    span.next {
        box-sizing: border-box;
        display: inline-block;
        background: {
            image: url('../images/arrow-breadcrumb.svg');
            position: center right;
            size: contain;
            repeat: no-repeat;
        }
        width: .85rem;
        height: .85rem;
        margin-right: .2em;
        margin-left: .4em;
        transform: translateY(.05em);
    }

    span.current {
        color: $theme-secondary-color;
    }
}