section {
    @include clearfix;
    box-sizing: border-box;
    position: relative;

    &.row {
        padding-top: $orange*1.5;
        padding-bottom: $magenta*1.8;

        @include tablet-portrait {
            padding-top: $green;
            padding-bottom: $orange*1.5;
        }
    }

    &.blanco,
    &.white {
        .mod-images.element-template--default {
            picture {
                &:after {
                    position: absolute;
                    top: -1px;
                    bottom: -1px;
                    right: -1px;
                    left: -1px;
                    z-index: 3;
                    content: '';
                    display: block;
                    pointer-events: none;
                    background: {
                        image: url('../images/image-blanco-front.svg');
                        size: cover;
                        position: center;
                        repeat: no-repeat;
                    }
                }
            }
        }
    }

    &.blanco,
    &.frame {
        + .highlight {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: 50;
                pointer-events: none;
                background: {
                    position: center top;
                    repeat: no-repeat;
                    size: cover;
                }
                width: 100%;
                //height: 100px;
                height: 5.556rem;
                top: -1px;
                transform: translateY(0);
            }
        }
    }

    &.blanco {
        &:before {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
            background: {
                image: url('../images/wave-blanco-top.svg');
                position: center top;
                repeat: no-repeat;
                size: cover;
            }
            width: 100%;
            //height: 100px;
            height: 5.556rem;
            top: 1px;
            transform: translateY(-100%);
        }

        .mod-images.element-template--default {
            picture {
                &:after {
                    background-image: url('../images/image-blanco-front.svg');
                }
            }
        }

        + .highlight {
            &:before {
                background-image: url('../images/wave-highlight-top-1.svg');
            }
        }
    }

    &.white {
        background-color: #fff;

        &:before {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
            background: {
                image: url('../images/wave-white-top.svg');
                position: center top;
                repeat: no-repeat;
                size: cover;
            }
            width: 100%;
            //height: 100px;
            height: 5.556rem;
            top: 1px;
            transform: translateY(-100%);
        }

        .mod-images.element-template--default {
            picture {
                &:after {
                    background-image: url('../images/image-white-front.svg');
                }
            }
        }
    }

    &.frame {
        background: $section-frame-color;
        color: $section-frame-font-color;

        #{$headers} {
            color: $section-frame-font-color;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            pointer-events: none;
            background: {
                image: url('../images/wave-frame-top.svg');
                position: center top;
                repeat: no-repeat;
                size: cover;
            }
            width: 100%;
            //height: 100px;
            height: 5.556rem;
            top: 1px;
            transform: translateY(-100%);
        }

        + .highlight {
            &:before {
                background-image: url('../images/wave-highlight-top-2.svg');
            }
        }
    }

    &.highlight {
        background: $section-highlight-color;
        color: #fff;
        margin-top: $magenta*1.25*-1;
        padding-top: $magenta;
        padding-bottom: $magenta;

        #{$headers}, a {
            color: #fff;
        }

        a:not( .button ) {
            text-decoration: underline;
        }

        .element-template--banner {
            opacity: .05;
        }
    }
}