.mod-links {
    @include clearfix;
    width: 100%;
    height: auto;

    li {
        position: relative;
        box-sizing: border-box;
    }

    &.element-template--logos {
        position: relative;

        .swiper-container {
            height: 160px;
            width: 90%;

            .swiper-wrapper {

                .swiper-slide {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    padding: $pink-rem;
                    box-shadow: inset 0 0 50px 0 rgba(0, 0, 0, 0.075);
                    box-sizing: border-box;

                    a {
                        display: block;

                        &[href="#"] {
                            pointer-events: none;
                        }
                    }
                }
            }
        }

        .swiper-pagination {
            width: 100%;
            bottom: $green-rem*-1;

            span.swiper-pagination-bullet {
                box-sizing: border-box;
                display: inline-block;
                margin: 0 .15em;

                &.swiper-pagination-bullet-active {
                    background: $theme-secondary-color;
                }
            }
        }

        .swiper-button {
            background: {
                image: url('../images/nav-logo-slider.svg');
                repeat: no-repeat;
                position: center;
                size: contain;
            }
            width: $blue-rem;
            height: $blue-rem;

            &.swiper-button-prev {
                left: 0;
                transform: rotate(180deg);
            }
            &.swiper-button-next {
                right: 0;

            }
        }
    }

    &.element-template--annual-report,
    &.element-template--publication {
        ul {
            @include clearfix;
            @include clear-ul;

            li {
                box-sizing: border-box;
                position: relative;
                background: #fff;
                border-radius: 10px;
                box-shadow: 0 10px 20px 0 rgba(#000, .15);
                text-align: center;
                padding: $green-rem;

                a {
                    box-sizing: border-box;
                    position: relative;
                    display: inline-block;
                    color: $theme-link-color;
                    font-weight: 700;

                    .image {
                        position: relative;
                        height: 100px;
                        margin-bottom: $pink-rem;

                        img {
                            position: absolute;
                            top: calc(50% - 45px);
                            left: calc(50% - 50px);
                            box-sizing: border-box;
                            max-width: 100px;
                            width: 100%;
                            height: auto;
                            padding: $blue-rem;
                        }

                        &:before {
                            content: '';
                            display: block;
                            background: #ccc;
                            width: 100px;
                            height: 100px;
                            border-radius: 100%;
                            position: absolute;
                            top: 0;
                            left: calc(50% - 50px);
                        }
                    }

                    .text {
                        position: relative;
                        display: inline-block;

                        h6 {
                            display: block;
                            font-weight: 700;
                            color: $theme-font-color;
                        }
                    }

                    span {
                        display: inline-block;
                    }
                }

                &:nth-child( 5n + 1 ) a .image:before {
                    background: $theme-secondary-color;
                }

                &:nth-child( 5n + 2 ) a .image:before {
                    background: $theme-primary-color;
                }

                &:nth-child( 5n + 3 ) a .image:before {
                    background: #3b3561
                }

                &:nth-child( 5n + 4 ) a .image:before {
                    background: #f4d06f;
                }

                &:nth-child( 5 ) a .image:before {
                    background: #ff8811
                }

                &:hover {
                    animation: wobble-hor-bottom 0.8s both;
                }
            }
        }
    }

    &.element-template--annual-report {
        ul {
            @include basic-columns (
                $count: 4,
                $selector: 'li',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: tablet-landscape
            );

            @include tablet-landscape {
                @include basic-columns (
                    $count: 3,
                    $selector: 'li',
                    $gutter: ($blue-rem, 2.4%),
                    $gutter-bottom: $blue-rem,
                    $breakpoint: tablet-portrait
                );
            }

            @include tablet-portrait {
                @include basic-columns (
                    $count: 2,
                    $selector: 'li',
                    $gutter: ($blue-rem, 2.4%),
                    $gutter-bottom: $blue-rem,
                    $breakpoint: iplusP
                );
            }

            a[href*="pdf"] {
                span:after {
                    content: '';
                    display: inline-block;
                    position: relative;
                    background: {
                        image: url('../images/pdf.svg');
                        repeat: no-repeat;
                        size: .85em;
                        position: center;
                    }
                    width: 1em;
                    height: 1em;
                    top: 2px;
                    left: 2px;
                }
            }
        }
    }

    &.element-template--publication {
        ul {
            @include basic-columns (
                $count: 3,
                $selector: 'li',
                $gutter: ($blue-rem, 2.4%),
                $gutter-bottom: $blue-rem,
                $breakpoint: tablet-portrait
            );

            @include tablet-portrait {
                @include basic-columns (
                    $count: 2,
                    $selector: 'li',
                    $gutter: ($blue-rem, 2.4%),
                    $gutter-bottom: $blue-rem,
                    $breakpoint: iplusP
                );
            }

            li {
                .text {
                    margin-bottom: $green-rem;
                }
            }
        }
    }

    &.element-template--video,
    &.element-template--video-apple {
        a.youtube-popup {
            position: relative;
            box-sizing: border-box;
            display: block;
            background: #fff;
            padding: 5px;
            border-radius: $red-rem;
            box-shadow: 0 10px 20px 0 rgba(#000, .15);
            outline: none;

            &:before,
            &:after {
                position: absolute;
                z-index: 3;
                top: 50%;
                left: 50%;
            }

            &:before {
                content: '';
                background: {
                    image: url('../images/play.svg');
                    size: contain;
                    repeat: no-repeat;
                    position: center;
                }
                width: $brown-rem*2;
                height: $brown-rem*2;
                transform: translate(-50%, -75%);
                transform-origin: center;
            }

            &:after {
                content: 'Speel video af';
                color: #fff;
                font-size: 1rem;
                font-weight: 700;
                display: block;
                transform: translate(-50%, 125%);
                transition: opacity .2s ease-in-out, transform .2s ease-in-out;
            }

            .gradient {
                pointer-events: none;
                box-sizing: border-box;
                position: absolute;
                z-index: 2;
                top: 5px;
                bottom: 5px;
                right: 5px;
                left: 5px;
                border-radius: $red-rem;
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.65) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
                opacity: 1;
                transition: .2s ease-in-out opacity;
            }

            img {
                border-radius: $red-rem;
                display: block;
                width: 100%;
                height: auto;
            }

            &:hover {
                &:before {
                    animation: pulsate-fwd-play-btn .8s ease-in-out infinite both;
                }

                &:after {
                    transform: translate(-50%, 50%);
                    opacity: 0;
                }

                .gradient {
                    opacity: .3;
                }
            }

            @include breakpoint(iplusP) {
                &:before {
                    width: $brown-rem;
                    height: $brown-rem;
                }
            }
        }

        h2 {
            font-weight: 700;
        }
    }

    &.element-template--video-apple {
        position: relative;

        a {
            z-index: 1;
        }

        h2 {
            pointer-events: none;
            position: absolute;
            bottom: $green-rem*-1;
            left: $green-rem*-1;
            z-index: 2;
            color: #fff;

            &:before {
                content: '';
                background: {
                    image: url('../images/appel.png');
                    repeat: no-repeat;
                    position: center;
                    size: contain;
                }
                width: 158px;
                height: 287px;
                display: inline-block;
            }

            span {
                position: relative;
                display: inline-block;
                transform: translate($pink-rem, $orange-rem*2*-1);
            }

            @include tablet-portrait {
                bottom: $magenta-rem*-1;

                &:before {
                    width: 128px;
                    height: 233px;
                }

                span {
                    position: relative;
                    display: inline-block;
                    transform: translate($brown-rem, $orange-rem*2*-1);
                }
            }

            @include breakpoint(iplus) {
                display: none;
            }
        }
    }
}