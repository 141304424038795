.mod-blog-list {
    @include clearfix;
    position: relative;
    box-sizing: border-box;
}

.element-template--small-overview {
    text-align: center;

    .news-items {
        @include clearfix;
        position: relative;
        box-sizing: border-box;

        @include basic-columns (
            $count: 3,
            $selector: '.news-item',
            $gutter: ($green, 3.16%),
            $gutter-bottom: $green,
            $breakpoint: tablet-portrait
        );

        @include tablet-portrait {
            @include basic-columns (
                $count: 2,
                $selector: '.news-item',
                $gutter: ($green, 3.16%),
                $gutter-bottom: $green,
                $breakpoint: iplusP
            );
        }
    }

    .news-item {
        @include clearfix;
        box-sizing: border-box;
        position: relative;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 10px 20px 0 rgba(#000, .15);
        text-align: center;
    }

    .wrapper {
        @include clearfix;
        position: relative;
        box-sizing: border-box;

        &.image {
            padding: $blue-rem;
            padding-bottom: 0;

            img {
                width: 100%;
                height: auto;
                max-width: 170px;
                display: block;
                border-radius: 100%;
                margin-right: auto;
                margin-left: auto;
            }
        }

        &.text {
            padding: $green-rem;
            padding-top: $blue-rem;

            h5 {
                font-weight: 700;
                margin-bottom: $pink-rem;

                a {
                    color: $theme-font-color;
                }
            }

            .tag-date {
                margin-bottom: $pink-rem;
                margin-right: .25em;
                margin-left: .25em;

                .tag {
                    display: inline-block;
                    font-size: .611em;
                    font-weight: 700;
                    text-transform: uppercase;
                    padding: $red-rem/1.8 $red-rem $red-rem/4 $red-rem;
                    background: #cacaca;
                    color: #fff;
                    border-radius: 3px;

                    &[class*="nieuws"] {
                        background: #3b3561;
                    }
                    &[class*="media"] {
                        background: $theme-primary-color;
                    }
                    &[class*="onderzoek"] {
                        background: #ef9552;
                    }
                }

                time {
                    margin-right: .25em;
                    margin-left: .25em;
                    display: inline-block;
                    font-size: .833em;
                    color: #3b3b3b;
                }
            }
        }
    }

    .button.read-more {
        margin-top: $green-rem;
    }
}