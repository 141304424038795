.layout-default {
    .banner {
        .element-template--boxed {
           padding: $green-rem;

            @include breakpoint(iplusP) {
                padding: $pink-rem;
            }
        }

        .banner-text {
            font-size: 1.222em;
            font-weight: 100;

            h2 {
                margin-bottom: $blue-rem;
                font-size: 1.944rem;
            }

            @include breakpoint(iplusP) {
                font-size: 1em;
            }
        }

        @include breakpoint(menubreak) {
            .banner-text:nth-child(1n) {
                padding-top: 27vh;
                padding-bottom: 27vh;
            }
        }

        @include breakpoint(iplus) {
            .banner-text:nth-child(1n) {
                padding-top: 10vh;
                padding-bottom: 15vh;
            }
        }
    }
}