@import url('//use.typekit.net/hin1vdh.css');
@import url('//fonts.googleapis.com/css?family=Cedarville+Cursive');

/* FONTS */
$font-family: "museo-sans-rounded", 'Arial', sans-serif;
$font-size: 18px;
$font-weight: 500;
$line-height: 1.5;

/* HEADS PRESETS */
$headers: h1, h2, h3, h4, h5, h6;

/* COLORS */
$theme-primary-color: #48a9a4;
$theme-secondary-color: #f26163;
$theme-font-color: #4c4c4c;
$theme-link-color: #48a9a6;

$section-frame-color: #cee8ef;
$section-frame-font-color: #163943;

$section-blank-color: #fff;

$section-highlight-color: $theme-primary-color;

$footer-background-color: #50a7a4;

$border-color: rgba($theme-font-color, .35);

/* CUSTOM BREAKPOINTS */
$breakpoints: map-merge($breakpoints, (
    iplusP: 414px,
    iplus: 736px,
    menubreak: 1220px,
    custom: 1302px,
    laptop: 1440px,
));

/* MARGINS */
$red: .556em; // 10px
$pink: 1.111em; // 20px
$blue: 1.667em; // 30px
$green: 2.222em; // 40px
$brown: 2.778em; // 50px
$orange: 3.889em; // 70px
$magenta: 4.444em; // 80px

$red-rem: .556rem;
$pink-rem: 1.111rem;
$blue-rem: 1.667rem;
$green-rem: 2.222rem;
$brown-rem: 2.778rem;
$orange-rem: 3.889rem;
$magenta-rem: 4.444rem;

/* COLUMNS */
$euphoria-column-gutter: $orange;
$euphoria-column-gutter-fallback: 6.3%;

/* BUTTON PRESETS */
$button-background: $theme-primary-color;
$button-text-color: #fff;
$button-hover-background: lighten($theme-primary-color, 10%);
$button-hover-text-color: #fff;
$button-padding: $pink-rem $pink $pink-rem $pink;
$button-border-radius: 3px;
$button-transition: all .3s ease-in-out;

/* FORM PRESETS */
$form-error-color: #DF6565;
$form-fieldset-border-color: #ededed;
$form-input-background-color: #fff;
$form-input-border: 1px solid rgba(#000, 0.14);
$form-input-border-radius: 0;
$form-input-color: #333;
$form-input-padding: .8333em 1em;
$form-input-placeholder-color: #898989;
$form-select-background-color: #f9f9f9;
$form-select-border-radius: 0;

/* HAMBURGER */
$hamburger-padding-x: $red;
$hamburger-padding-y: $red;
$hamburger-layer-width: $green;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $theme-link-color;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7;
$hamburger-hover-transition-duration: 0.1s;
$hamburger-hover-transition-timing-function: ease-in-out;

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@keyframes pulsate-fwd-play-btn {
    0% {
        transform: translate(-50%, -75%) scale(1);
    }
    50% {
        transform: translate(-50%, -75%) scale(1.1);
    }
    100% {
        transform: translate(-50%, -75%) scale(1);
    }
}

@keyframes jello-vertical {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(0.75, 1.25, 1);
    }
    40% {
        transform: scale3d(1.25, 0.75, 1);
    }
    50% {
        transform: scale3d(0.85, 1.15, 1);
    }
    65% {
        transform: scale3d(1.05, 0.95, 1);
    }
    75% {
        transform: scale3d(0.95, 1.05, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes shake-bottom {
    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 50% 100%;
    }
    10% {
        transform: rotate(1deg);
    }
    20%,
    40%,
    60% {
        transform: rotate(-2deg);
    }
    30%,
    50%,
    70% {
        transform: rotate(2deg);
    }
    80% {
        transform: rotate(-1deg);
    }
    90% {
        transform: rotate(1deg);
    }
}

@keyframes wobble-hor-bottom {
    0%,
    100% {
        transform: translateX(0%);
        transform-origin: 50% 50%;
    }
    15% {
        transform: translateX(-10px) rotate(-2deg);
    }
    30% {
        transform: translateX(5px) rotate(2deg);
    }
    45% {
        transform: translateX(-5px) rotate(-1.2deg);
    }
    60% {
        transform: translateX(3px) rotate(.8deg);
    }
    75% {
        transform: translateX(-2px) rotate(-.4deg);
    }
}

@keyframes shake-lr {
    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 50% 50%;
    }
    10% {
        transform: rotate(8deg);
    }
    20%,
    40%,
    60% {
        transform: rotate(-10deg);
    }
    30%,
    50%,
    70% {
        transform: rotate(10deg);
    }
    80% {
        transform: rotate(-8deg);
    }
    90% {
        transform: rotate(8deg);
    }
}




